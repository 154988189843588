.login_container {
	padding: 0;
	margin: 0;
	position: fixed;
	width: 100%;
	height: 100%;
	background: #dde2da;
}
.login_box {
	border: 1px solid #d1d1d152;
	background: #f3f7fb;
	margin-right: auto;
	max-width: 440px;
	padding: 44px;
	margin-bottom: 28px;
	background-color: #fff;
	-webkit-box-shadow: 0 2px 6px rgb(0 0 0 / 20%);
	-moz-box-shadow: 0 2px 6px rgba(0,0,0,0.2);
	box-shadow: 0 2px 6px rgb(0 0 0 / 20%);
	min-width: 320px;
	min-height: 338px;
	overflow: hidden;
	position: absolute;
	left: 50%;
	width: 440px;
	margin-left: -220px;
	top: 50%;
	margin-top: -169px;
}
.login_box p {
	padding: 45px 0 110px;
	font-weight: 300;
}
.login_box button {
	font-weight: 600;
}